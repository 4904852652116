import { render, staticRenderFns } from "./customerOrder.vue?vue&type=template&id=4ebf4526&scoped=true"
import script from "./customerOrder.vue?vue&type=script&lang=js"
export * from "./customerOrder.vue?vue&type=script&lang=js"
import style0 from "./customerOrder.vue?vue&type=style&index=0&id=4ebf4526&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ebf4526",
  null
  
)

export default component.exports